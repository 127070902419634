<nav class="navbar navbar-expand-lg bg-white navbar-absolute">
  <div class="container">
    <div class="navbar-translate">
      <!-- <a routerLink="/" class="navbar-brand"> Impostor Coding </a> -->
      <a routerLink="/" class="navbar-brand">
        <img src="/assets/img/logos/Impostor Coding Logo - Transparent.png" id="Impostor Coding Logo"/>
      </a>
      <button
        aria-controls="navbarSupportedContent"
        aria-label="Toggle navigation"
        class="navbar-toggler"
        [attr.aria-expanded]="!isCollapsed"
        (click)="isCollapsed = !isCollapsed"
        id="example-header-2"
        type="button"
      >
        <span class="navbar-toggler-icon"> </span>
      </button>
    </div>
    <div class="nav-container">
      <div class="navbar-collapse navbar-link-container" [isAnimated]="true" [collapse]="isCollapsed" id="example-header-2">
        <div class="navbar-collapse-header">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a routerLink="/"> Impostor Coding </a>
            </div>
            <div class="col-6 collapse-close text-right">
              <button
                aria-controls="navigation-index"
                aria-label="Toggle navigation"
                class="navbar-toggler"
                [attr.aria-expanded]="!isCollapsed"
                (click)="isCollapsed = !isCollapsed"
                id="example-header-2"
                type="button"
              >
                <span> </span> <span> </span>
              </button>
            </div>
          </div>
        </div>
        <ul class="navbar-nav mx-auto navbar-list">
          <li class="nav-item">
            <a routerLink="/" class="nav-link"> Posts </a>
          </li>
          <li class="nav-item">
            <a routerLink="/categories" class="nav-link"> Categories </a>
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-right navbar-social">
          <li class="nav-item">
            <a class="nav-link" href="https://twitter.com/impostor_coding" target="_blank">
              <i class="fab fa-twitter"> </i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.facebook.com/impostorcoding" target="_blank">
              <i class="fab fa-facebook-square"> </i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>