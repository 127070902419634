<footer class="footer">
  <div class="container">
    <div class="row row-grid align-items-center mb-5">
      <div class="col-lg-6">
        <h4 class="mb-0 font-weight-light">
          Don't forget to follow us for our latest post updates!
        </h4>
      </div>
      <div class="col-lg-6 text-lg-center btn-wrapper social-footer-buttons">
        <button
          class="btn btn-icon-only btn-twitter rounded-circle mr-3"
          href="https://twitter.com/impostor_coding"
          target="_blank"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-twitter"> </i>
          </span>
        </button>
        <button
          class="btn-icon-only rounded-circle btn btn-facebook mr-3"
          href="https://www.facebook.com/impostorcoding/"
          target="_blank"
        >
          <span class="btn-inner--icon">
            <i class="fab fa-facebook"> </i>
          </span>
        </button>
      </div>
    </div>
    <hr />
    <div class="row align-items-center justify-content-md-between">
      <div class="col-md-6">
        <div class="copyright">
          © {{ date | date: "yyyy" }}
          <a routerLink="/"> Impostor Coding </a> .
        </div>
      </div>
      <div class="col-md-6">
      </div>
    </div>
  </div>
</footer>
