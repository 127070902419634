<div class="container text-center">
  <div class="row align-items-center">
    <div *ngIf="loading" id="border-component" class="tab-pane tab-example-result fade show active" role="tabpanel" aria-labelledby="border-component-tab">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <ul *ngIf="!loading" class="col-md-12 category-list">
      <li *ngFor="let category of categories" class="col-md-12">
        <div class="card card-category card-background category-list-item" data-animation="zooming" (click)="viewCategory(category)">
          <div class="full-background">
            <!-- <img [src]="category.featured_image" /> -->
          </div>
          <div class="card-body">
            <div>
              <h5 class="card-title category-list-title">{{ category.name }}</h5>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>